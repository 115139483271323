import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91')
];

export const server_loads = [2,3,4,6];

export const dictionary = {
		"/(base)/(web)": [11,[2,3]],
		"/(base)/(web)/(authenticated)/(admin)/admin": [12,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/accounts": [13,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/accounts/[id]": [~14,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/connect/providers": [15,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/connect/providers/[id]": [16,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/portfolios/[id]": [17,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/portfolios/[id]/bulk-update": [18,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties": [19,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]": [20,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]/clone": [21,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/properties/[id]/update": [~22,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/purgeRedis": [~23,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/queue": [24,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/sync": [25,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/tools": [26,[2,4]],
		"/(base)/(web)/(authenticated)/(admin)/admin/tools/pdf-reader": [27,[2,4]],
		"/(base)/(web)/(authenticated)/billing": [29,[2,3]],
		"/(base)/(web)/(authenticated)/connect/providers": [30,[2,3]],
		"/(base)/(web)/(authenticated)/connect/providers/[id]": [31,[2,3]],
		"/(base)/(web)/contact-us": [59,[2,3]],
		"/(base)/(web)/glossary": [60,[2,3]],
		"/(base)/(web)/login": [61,[2,3]],
		"/(base)/(web)/logout": [62,[2,3]],
		"/(base)/(web)/map": [63,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios": [32,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/add-properties": [37,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/add": [36,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]": [33,[2,3]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/dashboard": [34,[2,5]],
		"/(base)/(web)/(authenticated)/my-portfolios/[id]/print/well-interest": [35,[2,5]],
		"/(base)/(web)/(authenticated)/my-properties": [38,[2,3]],
		"/(base)/(web)/(authenticated)/my-properties/add": [~55,[2,3]],
		"/(base)/(web)/(authenticated)/my-properties/quick-add": [56,[2,3]],
		"/(base)/(web)/(authenticated)/my-properties/[id]": [~39,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/build-estimate": [41,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/(admin)/debug": [40,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details": [42,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/beyond-producing-wells": [43,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/geographic-info": [44,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/lease-estimate": [45,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/monthly-revenue": [46,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/new-drilling": [47,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/new-permits": [48,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/new-wells": [49,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/property-info": [~50,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/sales-estimate": [51,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/details/well-information": [52,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/print-manager": [53,[2,6]],
		"/(base)/(web)/(authenticated)/my-properties/[id]/well-interest": [~54,[2,6]],
		"/(base)/(web)/privacy-policy": [88,[2,3]],
		"/(base)/(web)/(authenticated)/profile": [~57,[2,3]],
		"/(base)/(web)/(authenticated)/profile/payment-info/create": [~58,[2,3]],
		"/(base)/(web)/p/[id]": [~64,[2,7]],
		"/(base)/(web)/p/[id]/component/map": [65,[2,8]],
		"/(base)/(web)/p/[id]/details/beyond-producing-wells": [66,[2,7]],
		"/(base)/(web)/p/[id]/details/geographic-info": [67,[2,7]],
		"/(base)/(web)/p/[id]/details/lease-estimate": [68,[2,7]],
		"/(base)/(web)/p/[id]/details/monthly-revenue": [69,[2,7]],
		"/(base)/(web)/p/[id]/details/new-drilling": [70,[2,7]],
		"/(base)/(web)/p/[id]/details/new-permits": [71,[2,7]],
		"/(base)/(web)/p/[id]/details/new-wells": [72,[2,7]],
		"/(base)/(web)/p/[id]/details/property-info": [73,[2,7]],
		"/(base)/(web)/p/[id]/details/sales-estimate": [74,[2,7]],
		"/(base)/(web)/p/[id]/details/well-information": [75,[2,7]],
		"/(base)/(web)/p/[id]/print-manager": [87,[2,7]],
		"/(base)/(web)/p/[id]/print/all": [76,[2,10]],
		"/(base)/(web)/p/[id]/print/dashboard": [~77,[2,9]],
		"/(base)/(web)/p/[id]/print/details/beyond-producing-wells": [78,[2,9]],
		"/(base)/(web)/p/[id]/print/details/lease-estimate": [79,[2,9]],
		"/(base)/(web)/p/[id]/print/details/monthly-revenue": [80,[2,9]],
		"/(base)/(web)/p/[id]/print/details/new-drilling": [81,[2,9]],
		"/(base)/(web)/p/[id]/print/details/new-permits": [82,[2,9]],
		"/(base)/(web)/p/[id]/print/details/new-wells": [83,[2,9]],
		"/(base)/(web)/p/[id]/print/details/sales-estimate": [84,[2,9]],
		"/(base)/(web)/p/[id]/print/details/well-information": [85,[2,9]],
		"/(base)/(web)/p/[id]/print/well-interest": [~86,[2,9]],
		"/(base)/(web)/reset-password": [89,[2,3]],
		"/(base)/(web)/signup": [90,[2,3]],
		"/(base)/(web)/(authenticated)/(enterprise)/speculator": [28,[2,3]],
		"/(base)/(web)/terms-conditions": [91,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';